html,body,* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

* {
  border: none;
  padding: 0;
  margin: 0;
}

html {
  font-size: 16px;
}

body {
  /* background-image: url('/img/bg-laptop.jpg'); */
  background-size: cover;
  background-attachment: fixed;
  font-family: 'head';
}

/* Te rozdzielczosci musza zgadzac sie z tymi w responsive.js */
@media only screen and (min-width: 1921px) {
  body {
    /* background: rgba(255,0,0,0.3); */
  }
}
@media only screen and (max-width: 1920px) {
  body {
    /* background: green; */
  }
}
@media only screen and (max-width: 1366px) { /* my iPad Pro 1366px */
  body {
    /* background: yellow; */
  }
}
@media only screen and (max-width: 360px) {
  body {
    /* background: red; */
  }
}

/* .portfolio-item {
  margin-bottom: 25px;
}

footer {
  margin: 50px 0;
}

quote {
  font: italic 12pt Cambria;
}

[class*="col-"] {
  border: 1px solid blue;
}

#heading {
  position: absolute;
  width: 792px;
  height: 188px;
  left: 68px;
  top: 38px;
  background: black;
  opacity: 0.81;
  font: 42px 'Main Heading';
}

#heading > p.first {
  color: #fbdd5f;
}

#heading > p.second {
  color: #e0e0e0;
}

#heading > p {
  margin: 0;
  margin-left: 34px;
  letter-spacing: -1px;
}

#heading > p.first {
  margin-top: 14px;
}

#brands {
  width: 100%;
  position: absolute;
  left: 0;
  top: 264px;
  height: 136px;
  background: black;
  opacity: 0.81;
  background-image: url('/img/brands.png');
  background-repeat: no-repeat;
}

#brands > img.first {
  margin-left: 95px;
}

#brands > img {
  margin-top: 30px;
  margin-right: 30px;
}

#projects {
  position: absolute;
  width: 94%;
  min-height: 700px;
  margin-left: 2%;
  margin-right: 2%;
  top: 450px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}

#projects > .project {
  display: inline-block;
  width: 350px;
  height: 197px;
  filter: grayscale(50%);

  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-end;
  margin: 10px;
}

#projects > .project:hover {
  transition: all 300ms;
  filter: grayscale(0%);
}

#projects > .project > .title {
  width: 100%;
  background: black;
  opacity: 0.81;
  padding: 10px 20px;
}

@media only screen and (min-width: 414px) and (max-width: 1024px) {
  #heading {
      width: 450px;
      left: 20px;
      font-size: 26px;
      height: 130px;
  }

  #heading > p {
      margin-left: 20px;
  }

  #heading > p.first {
      margin-top: 20px;
  }
}

@media only screen and (max-width: 414px) {
  #heading {
      left: 5vw;
      top: 10vw;
      width: 90vw;
      font-size: 5.8vw;
      height: 28vw;
  }

  #heading > p {
      margin-left: 4vw;
  }

  #heading > p.first {
      margin-top: 4vw;
  }

  #brands {
      height: 60px;
      top: 140px;
  }

  #projects {
      width: 90%;
      top: 220px;
      left: 5%;
      margin: 0;
      justify-content: space-between;
  }

  #projects > .project {
      width: 48%;
      margin-left: 0%;
      margin-right: 0%;
  }

  #projects > .project > .title {
      font-size: 10px;
  }
}

@media only screen and (orientation: portrait) {
  #heading {
  }
}
 */
